// Read matomo config from meta, which is defined in head
const matomoConfig = document.querySelector('meta[name="matomo-config"]');

if (matomoConfig) {
  const url = matomoConfig.getAttribute("data-matomo-url");
  const siteId = matomoConfig.getAttribute("data-site-id");

  var _paq = (window._paq = window._paq || []);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(["trackPageView"]);
  _paq.push(["enableLinkTracking"]);
  (function () {
    var u = `//${url}/`;
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", siteId]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  })();
}
